import { atom } from "recoil";

export const paymentsFilterAtom = atom({
  key: "paymentsFilterAtom",
  default: {
    isCustomPeriod: false,
    from: "",
    to: "",
    period: "anytime",
    status: ["all"],
    search: "",
    method: [],
    custom_period: [],
  },
});

export const statusPaymentsAtom = atom({
  key: "statusPaymentsAtom",
  default: {
    all: 0,
    succeeded: 0,
    refunded: 0,
    partially_refunded: 0,
    settled: 0,
    failed: 0,
    open:0,
  },
});

export const paymentTableSettingsAtom = atom({
  key: "paymentTableSettingsAtom",
  default: {
    count: 20,
    page: 1,
    totalCounts: 0,
    totalPages: 0,
    loading: false,
    checkedKeys: [],
    checkAll: false,
    showMobileFilters: false,
  },
});

export const paymentTableDataAtom = atom({
  key: "paymentTableDataAtom",
  default: [],
});

export const exportExcelLoaderAtom = atom({
  key: "exportExcelLoaderAtom",
  default: false,
});
