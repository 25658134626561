import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Tooltip, Whisper } from "rsuite";
import styles from "./dynamic-tooltip.module.scss";

const Index = ({ className = "" }) => {
  const { t } = useTranslation("pages");

  const tooltip = (
    <Tooltip>
      <p>
        Give your customers the option to pay what they want for your product or
        service.
      </p>
    </Tooltip>
  );

  return (
    <Fragment>
      <span className={className}>Pay what you want</span>
      <Whisper placement="top" trigger="click" speaker={tooltip}>
        <Icon className={styles.iconInfo} icon="info-circle" />
      </Whisper>
    </Fragment>
  );
};

export default Index;
